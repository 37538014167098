<template>
  <section class="app-main">
    <div class="welcome" v-if="showUserName">
      {{ welcomeMessage }}
    </div>
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.path;
    },
    showUserName() {
      return ["/sellerConsole", "/buyerConsole"].includes(this.$route.path);
    },
    level() {
      let result = null;
      if (this.userInfo && this.userInfo.scoreLevel) {
        const num = this.userInfo.scoreLevel.replace(/\D/g, "");
        result = Number(num);
      }
      return result;
    },
    welcomeMessage() {
      let word = "Please log in!";
      if (this.companyDetail === null) {
        word = "Welcome back";
      } else if (this.companyDetail && this.companyDetail.companyName) {
        word = `Welcome back, ${this.companyDetail?.companyName}`;
      } else if (this.userInfo && this.userInfo.userName) {
        word = `Welcome back, ${this.userInfo.userName}`;
      }
      return word;
    },
  },
  created() {
    // this.router.push("/userScore");
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.app-main {
  /* 50= navbar  50  */
  height: calc(100vh - 101px);
  width: 100%;
  position: relative;
  overflow: auto;
  padding: 20px;
  min-height: 300px;
  .welcome {
    font-weight: 600;
    font-size: 20px;
    text-align: left;
    margin: 0 auto 30px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    .in {
      margin-left: 12px;
      cursor: pointer;
    }
  }
}
</style>
