<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "app",
};
</script>

<style lang="less">
#app {
  font-family: Circular Book;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5d7287;
  // text-align: center;
  .el-button.el-button--default {
    color: #0089ff;;
    border-color: #0089ff;;
    background: #fff;
    &:hover {
      color: #0089ff;;
      border-color: #0089ff;
      background: #ecf5ff;
    }
  }

  .el-button.el-button--default.is-disabled {
    color: #ccc;
    background: #f9f9f9;
    border-color: #ccc;
    &:focus,
    &:hover {
      color: #ccc !important;
      background: #f9f9f9 !important;
      border-color: #ccc !important;
    }
  }
  .el-button.el-button--primary {
    color: #fff;
    background: #0089ff;;
    border-color: #0089ff;;
    &:hover {
      color: #fff;
      background: #33a0ff;
      border-color: #33a0ff;
    }
  }
  .el-button.el-button--primary.is-disabled {
    color: #fff;
    background: #999;
    &:focus,
    &:hover {
      color: #fff !important;
      background: #999 !important;
      border-color: #999 !important;
    }
  }
}
.el-message-box__btns {
  .el-button.el-button--default {
    color: #0089ff;;
    border-color: #0089ff;;
    background: #fff;
    &:hover {
      color: #0089ff;;
      border-color: #0089ff;;
      background:#ecf5ff;
    }
  }

  .el-button.el-button--default.is-disabled {
    color: #ccc;
    background: #f9f9f9;
    border-color: #ccc;
    &:focus,
    &:hover {
      color: #ccc !important;
      background: #f9f9f9 !important;
      border-color: #ccc !important;
    }
  }
  .el-button.el-button--primary {
    color: #fff;
    background: #0089ff;;
    border-color: #0089ff;;
    &:hover {
      color: #fff;
      background: #33a0ff;
      border-color: #33a0ff;
    }
  }
  .el-button.el-button--primary.is-disabled {
    color: #fff;
    background: #33a0ff;
    border-color: #33a0ff;
    &:focus,
    &:hover {
      color: #fff !important;
      background: #33a0ff !important;
      border-color: #33a0ff !important;
    }
  }
}
</style>
