<template>
  <div class="footer">
    <div class="app-foot-inner">
      <div class="app-foot-content">
        <div class="app-main-content">
          <div style="margin-bottom: 25px">
            <div class="app-link-to">
              <span class="foot-content-title">Company</span>

              <a
                class="foot-content-link"
                :href="mailtoLink"
                @click.prevent="sendEmail"
                >springplusservice@gmail.com</a
              >
            </div>
            <div class="app-link-to">
              <span class="foot-content-title">Help & Support</span>
              <a
                class="foot-content-link"
                :href="mailtoLink"
                @click.prevent="sendEmail"
              >
                Custom Development</a
              >
              <span class="foot-content-link" @click="footLink('/ask')">
                Q&A </span
              ><span class="foot-content-link" @click="footLink('/document')">
                Documentation
              </span>
            </div>
            <div class="app-link-to">
              <span class="foot-content-title">Legal Information</span>
              <span
                class="foot-content-link"
                @click="footLink('/ask/detail/1844317283330158593')"
                >License</span
              >
              <span
                class="foot-content-link"
                @click="footLink('/ask/detail/1846554883113742338')"
                >Update Log</span
              >
            </div>
          </div>
          <!-- <div class="app-qrCode">
            <img src="@/assets/image/footer/QRcode.png" alt="QRcode" />
            <label>Connect us</label>
          </div> -->
        </div>
        <div class="divider-white"></div>
        <div class="divider-black"></div>

        <div class="copy-right">
          © 2024 - 2030 All Rights Reserved by JavaFast a Website from Low-Code
          Rapid Development Platform ..
        </div>
        <Agreement
          :modelValue="dialogVisible"
          @setDialogVisible="setDialogVisible"
        />
      </div>
    </div>
  </div>
</template>

<script>
import blogPostService from "@/service/api/bbs/blog/blogPostService";
const Agreement = () => import("@/views/agreement/index.vue");
export default {
  name: "Footer",
  props: {
    msg: String,
  },
  components: {
    Agreement,
  },
  created() {
    this.init();
  },
  data() {
    return {
      result: {
        hotPostList: [],
        topUserList: [],
        link: [],
      },
      email: "spirngplusservice@gmail.com", // 收件人邮箱
      subject: "", // 邮件主题
      body: "", // 邮件内容
      showDataCustom: false,
      dialogVisible: false,
    };
  },
  computed: {
    mailtoLink() {
      return `mailto:${this.email}?subject=${encodeURIComponent(
        this.subject
      )}&body=${encodeURIComponent(this.body)}`;
    },
  },
  methods: {
    async init() {
      this.result = await blogPostService.rightList();
    },
    sendEmail() {
      window.location.href = this.mailtoLink;
    },
    footLink: function (val) {
      if (!val) {
        return;
      }
      if (val.indexOf("http") == 0) {
        window.open(val);
      } else {
        this.$router.push(val);
      }
    },
    closeDataCustom() {
      this.showDataCustom = false;
    },
    setDialogVisible(flag) {
      this.dialogVisible = flag;
    },
    openDialog(val) {
      if (val == "data-custom") {
        this.showDataCustom = true;
      } else if (val == "user_agreement") {
        this.setDialogVisible(true);
      }
    },
    goLink: function () {
      window.open("https://beian.miit.gov.cn/");
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "~@/assets/css/default.less";
.footer {
  overflow: hidden;
  background: #2d353d;
  height: 323px;

  .app-foot-inner {
    .containerWidth;
    padding: 0 10px;
    .app-foot-content {
      width: 100%;
      margin-top: 25px;
      .app-link-to {
        display: flex;
        padding: 15px 0;
        font-weight: 400;
        .foot-content-title {
          font-size: 14px;
          color: #ffffff;
          font-weight: 600;
          float: left;
          margin-right: 40px;
        }
        .foot-content-link {
          font-size: 14px;
          opacity: 0.4;
          color: #ffffff;
          margin-right: 30px;
          cursor: pointer;
        }
        .foot-content-about {
          font-size: 14px;
          color: #ffffff;
          margin-right: 30px;
          cursor: pointer;
        }
        .foot-content-info {
          margin-right: 30px;
          font-size: 14px;
          color: #ffffff;
        }
      }
      .app-main-content {
        position: relative;
        .link-items {
          display: block;
          .app-link-to;
        }
        .app-qrCode {
          position: absolute;
          right: 10px;
          text-align: center;
          top: 12px;
          img {
            width: 100px;
            display: block;
            margin-bottom: 8px;
          }
          label {
            font-size: 14px;
            color: #fff;
          }
        }
      }
      .divider-white {
        height: 1px;
        width: 100%;
        opacity: 0.1;
        background: #ffffff;
      }
      .divider-black {
        height: 1px;
        width: 100%;
        opacity: 0.2;
        background: #000000;
      }
      .about-us {
        // margin: 15px 0;
      }
      .copy-right {
        text-align: center;
        width: 100%;
        height: 40px;
        line-height: 40px;
        margin-top: 15px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        .beian {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
