<template>
  <el-dialog
    width="500px"
    append-to-body
    :modal="true"
    :show-close="false"
    :visible.sync="dialogVisible"
    :close-on-click-modal="true"
  >
    <div class="login-content" v-loading="loginLoading">
      <!-- <el-tabs v-model="state.type"> -->
      <!-- <el-tab-pane label="帐户密码登录" name="account"> -->
      <p class="title">微信扫码一键登录</p>
      <wxlogin
        href="data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7CiAgICAgd2lkdGg6IDE4MHB4OwogICAgaGVpZ2h0OiAxODBweDsKICAgIG1hcmdpbjogNjdweCA2N3B4IDBweCA2N3B4OwogICBib3JkZXI6IG5vbmU7CiAgICBkaXNwbGF5OiBibG9jazsKfQouaW1wb3dlckJveCAudGl0bGUgewogICAgZGlzcGxheTogbm9uZTsKfQouaW1wb3dlckJveCAuc3RhdHVzLnN0YXR1c19icm93c2VyIHsKICAgIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLnN0YXR1cyB7CiAgICBwYWRkaW5nOiA3cHggMTRweDsKICAgIHRleHQtYWxpZ246IGNlbnRlcjsKfQouaW1wb3dlckJveCAuc3RhdHVzX3R4dCB7CiAgICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7CiAgICB2ZXJ0aWNhbC1hbGlnbjogbWlkZGxlOwogICAgdGV4dC1hbGlnbjogbGVmdDsKfQ=="
        id="wxcode"
        theme="black"
        appid="wx55963e610b00c2e4"
        :state="getState()"
        scope="snsapi_login"
        :redirect_uri="encodeURIComponent(redirect_uri)"
      ></wxlogin>
      <p class="desc">打开微信扫一扫</p>
    </div>
  </el-dialog>
</template>
<script>
import { SelfElMessage } from "@/utils/utils";
import loginService from "@/service/api/loginService";
import qs from "qs";
import wxlogin from "vue-wxlogin";
// import { Base64 } from "js-base64";
// import EmailCode from "@/components/emailCode.vue";

export default {
  name: "Login",
  components: {
    wxlogin,
    // EmailCode
  },
  data() {
    return {
      loginType: 2,
      dialogVisible: false,
      tabType: "account",
      redirect_uri: "https://www.JavaFast.org/#/getWxInfo",
      state: {
        userName: "",
        password: "",
        captcha: "",
        captchaImg: "",
        captchaKey: "",
        phoneNo: "",
        phoneSecurityCode: "",
        type: "account",
      },
      accountRules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        captcha: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      phoneRules: {
        phoneNo: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            len: 11,
            message: "手机号输入有误",
            pattern:
              /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/,
            trigger: "blur",
          },
        ],
        phoneSecurityCode: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
      },
      passwordStatus: true,
      imgCodeLoading: false,
      loginLoading: false,
      visibleDialog: false,
    };
  },
  watch: {
    $route() {
      this.close();
    },
  },
  methods: {
    init() {
      !this.dialogVisible && (this.dialogVisible = true);
      this.$store.dispatch("savePreviousRoute", this.$route);
    },
    close() {
      this.dialogVisible = false;
    },
    getState() {
      let state = parseInt(Math.random() * Date.now());
      // 将state写入storage，供后续比对
      sessionStorage.setItem("wxlogin_state", state);
      return String(state);
    },
    onlyNum(value) {
      value = value.replace(/[^0-9]/g, "");
      value = value.substr(0, 11);
      this.state.phoneNo = value;
    },
    linkToRegister() {
      this.$router.push({ path: "/register" });
    },
    linkToForgetPassword() {
      this.$router.push({ path: "/forgetPassword" });
    },
    onAccountSubmit() {
      this.$refs.refAccount.validate((valid) => {
        if (valid) {
          this.onAccountLogin();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async onAccountLogin() {
      this.loginLoading = true;
      try {
        const res = await loginService.login({
          userName: this.state.userName,
          password: this.state.password,
          captcha: this.state.captcha,
          captchaKey: this.state.captchaKey,
        });
        this.loginLoading = false;
        SelfElMessage({ type: "success", message: "账户登录成功" });
        this.$store.dispatch("setUserInfo", res);
        this.loginAfterRouter(res["f-token"]);
      } catch (e) {
        this.loginLoading = false;
      }
    },
    loginAfterRouter(token) {
      if (this.$route.query && this.$route.query.from) {
        let hasQuestionmark = this.$route.query.from.includes("?");
        window.location.href =
          this.$route.query.from +
          `${hasQuestionmark ? "&" : "?"}token=${token}`;
        return;
      }
      let beforeRoute = sessionStorage.getItem("beforeRoute");
      if (beforeRoute) {
        if (beforeRoute.indexOf("?") > -1) {
          let pathArr = beforeRoute.split("?");
          this.$router.push({ path: pathArr[0], query: qs.parse(pathArr[1]) });
        } else {
          this.$router.push(beforeRoute);
        }
        sessionStorage.removeItem("beforeRoute");
      } else {
        this.$router.push("/home");
      }
    },
    setPasswordStatus(flag) {
      this.passwordStatus = flag;
    },
    onShowDialog(val) {
      this.visibleDialog = val;
      if (val == false) {
        this.$store.dispatch("loginOut");
      }
    },
  },
};
</script>

<style lang="less">
.login-content {
  .title {
    font-size: 24px;
    font-weight: 500;
    color: #000;
    text-align: center;
  }
  #wxcode {
    text-align: center;
    height: 350px;
  }
  .desc {
    text-align: center;
    color: #000;
  }
}
</style>
