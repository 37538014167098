<template>
  <div>
    <navbar @changeRole="changeRole" />
    <div class="content">
      <side-bar :role="role" />
      <app-main />
    </div>
  </div>
</template>

<script>
import Navbar from "./Navbar.vue";
import SideBar from "./components/Siderbar/index.vue";
import AppMain from "./components/AppMain.vue";
// import { getUserMessage } from "@/services/api/login";
export default {
  name: "layout",
  components: {
    SideBar,
    AppMain,
    Navbar,
  },
  created() {
    if (window.navigator.language.indexOf("zh") >= 0) {
      this.$router.push("/error");
    } else {
      this.$dictUtils.refreshDictList();
    }
  },
  data() {
    return {
      role: "user",
    };
  },
  mounted() {
    // this.getIsAttention();
  },
  methods: {
    changeRole(role) {
      this.role = role;
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
}
</style>
