<template>
  <div class="sidebar-wrapper">
    <el-scrollbar>
      <el-menu v-if="role === 'user'" :default-active="activeMenu">
        <router-link to="/userCenter/home">
          <el-menu-item index="/userCenter/home">
            <i class="el-icon-s-home"></i>
            <span slot="title">My Home Page</span>
          </el-menu-item>
        </router-link>
        <router-link to="/userCenter/userInfo">
          <el-menu-item index="/userCenter/userInfo">
            <i class="el-icon-user-solid"></i>
            <span slot="title">Account Information</span>
          </el-menu-item>
        </router-link>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-s-order"></i>
            <span>My Posts</span>
          </template>
          <el-menu-item-group>
            <router-link to="/userCenter/myPost">
              <el-menu-item index="/userCenter/myPost"
                >Posts I Published</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/myCollectedPost">
              <el-menu-item index="/userCenter/myCollectedPost"
                >My Favorites
              </el-menu-item>
            </router-link>
          </el-menu-item-group>
        </el-submenu>
        <router-link to="/userCenter/myMessage">
          <el-menu-item index="/userCenter/myMessage">
            <i class="el-icon-chat-dot-round"></i>
            <span slot="title">My Messages</span>
          </el-menu-item>
        </router-link>
        <el-submenu index="5">
          <template slot="title">
            <i class="el-icon-s-finance"></i>
            <span>My Products</span>
          </template>
          <el-menu-item-group>
            <router-link to="/userCenter/myProduct">
              <el-menu-item index="/userCenter/myProduct"
                >Purchased Products</el-menu-item
              >
            </router-link>
            <!-- <router-link to="/userCenter/myService">
            <el-menu-item index="/userCenter/myService">Subscribed Services</el-menu-item>
          </router-link> -->
          </el-menu-item-group>
        </el-submenu>
        <!-- <el-submenu index="6">
          <template slot="title">
            <i class="el-icon-shopping-cart-2"></i>
            <span>Sell Applications</span>
          </template>
          <el-menu-item-group>
            <router-link to="/userCenter/myPublishProduct">
              <el-menu-item index="/userCenter/myPublishProduct"
                >Applications I Uploaded</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/myPublishCase">
              <el-menu-item index="/userCenter/myPublishCase"
                >Cases I Published</el-menu-item
              >
            </router-link>
          </el-menu-item-group>
        </el-submenu> -->
      </el-menu>

      <el-menu v-else :default-active="activeMenu">
        <router-link to="/userCenter/admin/home">
          <el-menu-item index="/userCenter/admin/home">
            <i class="el-icon-s-home"></i>
            <span slot="title">Admin Console</span>
          </el-menu-item>
        </router-link>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-s-claim"></i>
            <span>Posts Pending Review</span>
          </template>
          <el-menu-item-group>
            <router-link to="/userCenter/admin/today/post">
              <el-menu-item index="/userCenter/admin/today/post"
                >New Posts Today</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/today/message">
              <el-menu-item index="/userCenter/admin/today/message"
                >New Comments Today</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/audit/post">
              <el-menu-item index="/userCenter/admin/audit/post"
                >Posts Pending Review</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/audit/message">
              <el-menu-item index="/userCenter/admin/audit/message"
                >Comments Pending Review</el-menu-item
              >
            </router-link>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-s-custom"></i>
            <span>User Management</span>
          </template>
          <el-menu-item-group>
            <router-link to="/userCenter/admin/auth/user/list">
              <el-menu-item index="/userCenter/admin/auth/user/list"
                >User Management</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/auth/login/list">
              <el-menu-item index="/userCenter/admin/auth/login/list"
                >Login Records</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/auth/access/list2">
              <el-menu-item index="/userCenter/admin/auth/access/list2"
                >Forbidden Records</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/auth/access/list">
              <el-menu-item index="/userCenter/admin/auth/access/list"
                >Access Records</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/auth/attack/list">
              <el-menu-item index="/userCenter/admin/auth/attack/list"
                >Attack Behavior</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/auth/cooperation/list">
              <el-menu-item index="/userCenter/admin/auth/cooperation/list"
                >Cooperation Applications</el-menu-item
              >
            </router-link>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="5">
          <template slot="title">
            <i class="el-icon-s-shop"></i>
            <span>Product Delivery</span>
          </template>
          <el-menu-item-group>
            <router-link to="/userCenter/admin/auth/product">
              <el-menu-item index="/userCenter/admin/auth/product"
                >Product Delivery</el-menu-item
              >
            </router-link>
          </el-menu-item-group>
          <el-menu-item-group>
            <router-link to="/userCenter/admin/auth/orderList">
              <el-menu-item index="/userCenter/admin/auth/orderList"
                >Order Management</el-menu-item
              >
            </router-link>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="6">
          <template slot="title">
            <i class="el-icon-s-release"></i>
            <span>Applications</span>
          </template>
          <el-menu-item-group>
            <router-link to="/userCenter/admin/audit/product">
              <el-menu-item index="/userCenter/admin/audit/product"
                >Applications Upload</el-menu-item
              >
            </router-link>
          </el-menu-item-group>
          <!-- <el-menu-item-group>
            <router-link to="/userCenter/admin/audit/case">
              <el-menu-item index="/userCenter/admin/audit/case"
                >Cases Pending Review</el-menu-item
              >
            </router-link>
          </el-menu-item-group> -->
        </el-submenu>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: "sidebar",
  props: ["role"],
  computed: {
    activeMenu() {
      const route = this.$route;
      const { path } = route;
      return path;
    },
  },
};
</script>

<style lang="less">
.el-scrollbar {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.sidebar-wrapper {
  width: 240px;
  min-height: calc(100vh - 61px);
  .el-menu,
  .el-scrollbar__view {
    height: 100% !important;
    background-color: #f5f7f9;
  }
  .el-submenu__title:hover {
    background-color: #e6e9eb;
  }
  .el-submenu {
    text-align: left;
  }
  .el-menu-item {
    text-align: left;
    &:focus {
      background-color: rgba(0, 0, 0, 0);
    }
    &:hover {
      background-color: #e6e9eb;
    }
  }
}
.el-submenu__title i {
  color: #909399 !important;
}
</style>
