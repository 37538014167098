<template>
  <div class="navbar">
    <span
      style="
        display: inline-block;
        width: 204px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        float: left;
      "
    >
      <router-link to="/home"> JavaFast </router-link>
    </span>
    <el-select
      v-if="currentUser.role == 'admin'"
      class="console"
      v-model="console"
      size="mini"
      @change="changeConsole"
    >
      <el-option
        v-for="item in consoleOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
        <span>{{ item.label }}</span>
        <span>Switch</span>
      </el-option>
    </el-select>
    <div class="user-info">
      <span style="margin-right: 25px">
        <el-badge :value="currentUser.messageCount" :max="99" class="item">
          <el-link :underline="false" href="/#/userCenter/myMessage">
            <i width="38" class="message el-icon-chat-dot-round"></i>
          </el-link>
        </el-badge>
      </span>
      <div class="username">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <el-avatar
              :size="28"
              :src="currentUser.avatar"
              style="position: relative; top: 7px; right: 5px"
            ></el-avatar>
            <span class="name">{{ currentUser.nickname }}</span> |
            <span class="title"> {{ currentUser.title }}</span>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="userInfo"
                >Account Information</el-dropdown-item
              >
              <el-dropdown-item command="logout">Logout</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/service/token";
import loginService from "@/service/api/loginService";
import { SelfElMessage } from "@/utils/utils";
export default {
  data() {
    return {
      console: "user",
      consoleOptions: [
        {
          value: "user",
          label: "会员控制台",
        },
        {
          value: "admin",
          label: "管理员控制台",
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUser;
    },
    currentToken() {
      return getToken();
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    $route: {
      handler(to) {
        this.console = to.fullPath.includes("/admin") ? "admin" : "user";
        this.$emit("changeRole", this.console);
      },
      immediate: true,
    },
  },
  methods: {
    async init() {
      if (getToken()) {
        try {
          this.$store.dispatch("getUserInfo").then(() => {
            console.log("login success");
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
    changeConsole(value) {
      this.$emit("changeRole", value);
      if (value == "admin") {
        this.$router.push("/userCenter/admin/home");
      } else {
        this.$router.push("/userCenter/home");
      }
    },
    backToHome() {
      window.location.href = "/";
    },
    loginOut() {
      this.$confirm("Are you sure you want to log out?", "Prompt", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          loginService.logout().then(() => {
            this.$store.dispatch("loginOut");
            // this.$router.replace({ path: "/home" });
            window.location.href = "/home";
            SelfElMessage({ type: "success", message: "Logout successful" });
          });
        })
        .catch(() => {
          console.log("error!!");
        });
    },

    async handleCommand(command) {
      if (command === "logout") {
        this.loginOut();
      }
      if (command === "userInfo") {
        this.$router.push("/userCenter/userInfo");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  line-height: 25px;
  margin-right: 5px;
}
.message {
  font-size: 25px;
}
.navbar {
  position: relative;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #e5e7e9;
  .logo {
    position: absolute;
    left: 10.8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .console {
    margin-left: 280px;
  }
  .user-info {
    position: absolute;
    top: 0;
    right: 40px;
    font-size: 14px;
    color: #111;
    .help {
      margin-right: 28px;
      &:hover {
        color: #118dfd;
        cursor: pointer;
      }
    }
    img {
      margin: 0 15px;
      transform: translateY(3px);
    }
    .backup {
      margin-right: 0;
    }
    .username {
      display: inline-block;
      cursor: pointer;
      .level {
        position: absolute;
        left: 0px;
        bottom: -3px;
      }
      .name {
        margin-left: 8px;
      }
    }
  }
}
.el-select-dropdown__item {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  color: #111;
  cursor: default;
  width: 160px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }
  &.selected {
    display: none;
    font-weight: 400;
    color: #111;
  }
  span {
    &:last-child {
      color: #118dfd;
    }
  }
}
.el-select {
  ::v-deep .el-icon-arrow-up {
    font-family: element-icons !important;
    transform: none !important;
    line-height: 25px;
  }
  ::v-deep .el-icon-arrow-up:before {
    content: "\e6ea";
    color: #666;
    font-size: 12px;
  }
  ::v-deep .el-select__caret {
    &.is-reverse {
      transform: none;
    }
  }
}
</style>
<style lang="less">
.navbar {
  .console .el-input__inner {
    border: none;
    border-radius: 2px;
    width: 112px;
    padding-left: 10px;
    padding-right: 30px;
    height: 26px;
    line-height: 26px;
    background: #f0f2f5;
  }
  .search {
    margin-left: 263px;
    width: 265px;
    .el-input__inner {
      border: none;
      border-radius: 2px;
      height: 32px;
      line-height: 32px;
      background: #f0f2f5;
    }
  }
  .title {
    color: #fff;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 10px;
    background-image: linear-gradient(90deg, #fe8800 0%, #ff5500 100%);
  }
}
</style>
