import { Message, MessageBox, Loading } from "element-ui";
import { getToken, clearToken } from "@/service/token";
import { uuid } from "@/utils/utils";
import axios from "axios";
import router from "@/router";
import store from "@/store";
// import axiosRetry from 'axios-retry'
import qs from "qs";

// Timeout duration
axios.defaults.timeout = 9900000;
// Cross-domain requests, allow saving cookies
axios.defaults.withCredentials = true;
axios.defaults.headers = { "Content-Type": "application/json; charset=utf-8" };
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'
// Non-production environment && enable proxy, use [/api] prefix for proxy interception!
const BASE_URL =
  process.env.NODE_ENV !== "production"
    ? process.env.VUE_APP_BASE_API
    : process.env.VUE_APP_SERVER_URL;
// The base request path exposed by the other side
axios.BASE_URL = BASE_URL;

/**
 * Request Interceptor
 */
let loading;
axios.interceptors.request.use(
  (config) => {
    let showLoading = false;
    if (config.loading === true) {
      showLoading = true;
    }
    if (showLoading) {
      loading = Loading.service({
        text: config.loadingText || "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    }

    if (!config.headers.uuid) {
      config.headers.uuid = uuid();
    }
    if (config.headers["f-token"] === false) {
      delete config.headers["f-token"];
    } else {
      if (getToken()) {
        config.headers["f-token"] = getToken();
      }
    }

    // Request header includes the current domain name
    config.headers.domain = window.location.hostname;
    // Request URL processing
    if (!config.url.startsWith("http")) {
      config.url = BASE_URL + config.url;
    }

    const type = config.method;
    const arrayFormat = config.headers.arrayFormat || "indices";
    if (
      type === "post" &&
      config.headers["Content-Type"] ===
        "application/x-www-form-urlencoded; charset=utf-8"
    ) {
      // Post request parameter processing
      config.data = qs.stringify(config.data, {
        allowDots: true,
        arrayFormat: arrayFormat,
      });
    } else if (type === "get") {
      // Get request parameter processing
      config.paramsSerializer = (params) => {
        return qs.stringify(params, {
          allowDots: true,
          arrayFormat: arrayFormat,
        });
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Response Interceptor
 */
axios.interceptors.response.use(
  (response) => {
    if (loading) {
      loading.close();
    }
    return response.data;
  },
  (error) => {
    if (loading) {
      loading.close();
    }
    // eslint-disable-next-line no-debugger
    if (error.response) {
      // eslint-disable-next-line no-debugger
      if (error.response.status === 408) {
        // Timeout auto-refresh
        MessageBox.confirm(
          "The current user has been logged out or does not have permission to access the current resource. Please try to log in again before proceeding.",
          "No Permission Access",
          {
            type: "error",
            closeOnClickModal: false,
            center: true,
            confirmButtonText: "Log In Again",
          }
        )
          .then(() => {
            // clearLoginInfo();
            clearToken();
            store.dispatch("loginOut");
            router.replace({ path: "/login" });
          })
          .catch(() => {
            clearToken();
            store.dispatch("loginOut");
          });
      } else if (error.response.status === 401) {
        // Need to log in again
        Message.error({
          title: "Login Failed",
          message: error.response.data,
        });
        // clearLoginInfo();
        localStorage.removeItem("f-token");
        router.replace({ path: "/login" });
      } else if (error.response.status === 404) {
        // Path not found
        Message.error({
          title: "404",
          message: "Path not found" + ": " + error.config.url,
        });
      } else if (error.response.status === 503) {
        Message.error({
          title: "503",
          message: "Service Unavailable" + ": " + error.config.url,
        });
      } else if (error.response.status === 504) {
        Message.error({
          title: "504",
          message: "Network Connection Error" + ": " + error.data,
        });
      } else if (error.response.status === 400) {
        Message.error({
          title: "Request Failed",
          message: error.response.data || error.data || error,
        });
      } else {
        Message.error({
          title: "System Error",
          message:
            (error.response && error.response.data) || error.data || error,
        });
      }
    }

    return Promise.reject(error);
  }
);

export default axios;